import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64')
];

export const server_loads = [9,2,13];

export const dictionary = {
		"/(marketing)/(with-header)": [40,[9,11]],
		"/(marketing)/(with-header)/about": [41,[9,11]],
		"/(admin)/account/me": [~14,[2,3,4]],
		"/(admin)/account/me/danger": [15,[2,3,4]],
		"/(admin)/account/me/security": [16,[2,3,4]],
		"/(marketing)/(with-header)/blog": [~42,[9,11]],
		"/(marketing)/(with-header)/blog/[slug]": [~43,[9,11]],
		"/(admin)/brand/[teamSlug]/(menu)": [~17,[2,5]],
		"/(admin)/brand/[teamSlug]/api": [28,[2]],
		"/(admin)/brand/[teamSlug]/(menu)/competitions/[id]": [~18,[2,5]],
		"/(admin)/brand/[teamSlug]/competitions/[id]/edit": [~29,[2]],
		"/(admin)/brand/[teamSlug]/(menu)/settings": [19,[2,5,6]],
		"/(admin)/brand/[teamSlug]/(menu)/settings/billing": [~20,[2,5,6]],
		"/(admin)/brand/[teamSlug]/(menu)/settings/integrations": [21,[2,5,6]],
		"/(admin)/brand/[teamSlug]/(menu)/settings/integrations/klaviyo": [~22,[2,5,6]],
		"/(admin)/brand/[teamSlug]/(menu)/settings/integrations/mailchimp": [~23,[2,5,6]],
		"/(admin)/brand/[teamSlug]/(menu)/settings/integrations/mailjet": [~24,[2,5,6]],
		"/(admin)/brand/[teamSlug]/(menu)/settings/integrations/shopify": [~25,[2,5,6]],
		"/(admin)/brand/[teamSlug]/(menu)/settings/members": [~26,[2,5,6]],
		"/(admin)/brand/[teamSlug]/(menu)/users": [~27,[2,5]],
		"/connect/integrations/shopify": [~62,[13]],
		"/(marketing)/(with-header)/customers": [44,[9,11]],
		"/(giveaway)/c/[slug]": [~31,[8]],
		"/(giveaway)/c/[slug]/embed": [~32,[8]],
		"/(giveaway)/c/[slug]/terms-and-conditions": [~33,[8]],
		"/(giveaway)/demo/[slug]": [~34],
		"/(marketing)/(with-header)/dice-roller": [45,[9,11]],
		"/(marketing)/features": [60,[9]],
		"/(marketing)/(auth)/forgot-password": [36,[9,10]],
		"/(marketing)/(with-header)/giveaway": [~46,[9,11]],
		"/(marketing)/(with-header)/guides": [~47,[9,11]],
		"/(marketing)/(with-header)/guides/[slug]": [~48,[9,11]],
		"/(marketing)/(with-header)/instagram-comment-picker": [49,[9,11]],
		"/(marketing)/(with-header)/instagram-comment-picker/redirect": [~50],
		"/(marketing)/(with-header)/integrations": [51,[9,11]],
		"/(marketing)/invite/[id]": [~61,[9]],
		"/(marketing)/(with-header)/ltd-thank-you": [53,[9,11]],
		"/(marketing)/(with-header)/ltd": [52,[9,11]],
		"/(admin)/onboarding": [30,[2,7]],
		"/(giveaway)/preview/[domain]": [~35],
		"/(marketing)/(with-header)/pricing": [54,[9,11]],
		"/(marketing)/(with-header)/privacy": [55,[9,11]],
		"/(marketing)/(with-header)/random-name-picker": [56,[9,11]],
		"/redirect": [63],
		"/(marketing)/(auth)/sign-in": [37,[9,10]],
		"/sign-out": [~64],
		"/(marketing)/(auth)/sign-up": [38,[9,10]],
		"/(marketing)/(with-header)/support": [57,[9,11]],
		"/(marketing)/(with-header)/templates": [58,[9,11]],
		"/(marketing)/(with-header)/terms": [59,[9,11]],
		"/(marketing)/(auth)/update-password": [39,[9,10]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';